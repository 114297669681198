import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../../components/RichText/RichText'
import Typography from '../../components/Typography/Typography'
import LinkButton from '../../components/LinkButton/LinkButton'
import styles from './Error.tw-styles'

const ErrorPageContainer = ({
  headLine,
  subHeadline,
  description,
  callToActionsCollection,
}) => (
  <div className={styles.errorwrapper}>
    <Typography markdown={headLine} className={styles.errorheader} />
    <Typography markdown={subHeadline} className={styles.errorsubtitle} />
    <RichText {...description} parseHTML className={styles.errorcontent} />
    <div className={styles.errorbuttonwarpper}>
      {callToActionsCollection
        && callToActionsCollection.items
        && callToActionsCollection.items.map((item) => (
          <LinkButton
            {...item}
            key={item && item.sys && item.sys.id}
            className={styles.errorbutton}
          />
        ))}
    </div>
  </div>
)
ErrorPageContainer.propTypes = {
  headLine: PropTypes.string.isRequired,
  subHeadline: PropTypes.string.isRequired,
  description: PropTypes.shape({
    styles: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  callToActionsCollection: PropTypes.instanceOf(Object).isRequired,
}
export default ErrorPageContainer
