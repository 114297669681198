import React from 'react'
import PropTypes from 'prop-types'
import errorQuery from '../containers/Error/ErrorQuery'
import { getPage } from '../adapters/contentful.adapters'
import Layout from '../components/Layout/Layout'
import MetaData from '../components/MetaData/MetaData'
import ErrorPageContainer from '../containers/Error/Error'
import layoutQuery from '../graphql/queries/layoutQuery'

const ErrorPage = ({ page, layoutData }) => (
  <Layout {...layoutData} errorPage>
    <MetaData />
    <ErrorPageContainer {...page} />
  </Layout>
)

ErrorPage.propTypes = {
  page: PropTypes.oneOfType([PropTypes.object]).isRequired,
  layoutData: PropTypes.oneOfType([PropTypes.object]).isRequired,
}
export const getStaticProps = async () => {
  const page = await getPage('errorCollection', errorQuery)
  const layoutData = await getPage('layoutCollection', layoutQuery)
  return {
    props: { layoutData, page },
  }
}
export default ErrorPage
